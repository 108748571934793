import React from 'react';
import { useCitiesSelection } from '@planity/helpers';
import { useTranslation } from '@planity/localization';
import { BottomLinks } from '../../ui';
import styles from './cities_selection.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';

export const CitiesSelection = ({ baseSearch }) => {
	useStyles(styles);
	const { t } = useTranslation();
	const citiesSelection = useCitiesSelection(baseSearch);

	const isReplacingLinks =
		baseSearch?.category?.parentId === 'beauty_salon' &&
		baseSearch?.category?.name?.toLowerCase().includes('massage');

	const generateMassageLinks = () => {
		if (!citiesSelection) return [];
		return citiesSelection.map(({ link, label }) => ({
			link: link.replace(
				'/institut-de-beaute/',
				'/institut-de-beaute/massage/'
			),
			label: label.replace('Instituts de beauté', 'Salon de massage')
		}));
	};

	const finalLinks = isReplacingLinks
		? generateMassageLinks()
		: citiesSelection;

	const baseCategoryName = isReplacingLinks
		? baseSearch?.category?.name
		: baseSearch?.parentCategory?.name;

	return (
		<BottomLinks
			className={styles.linkWrapper}
			getPath={link => link.link || ''}
			getText={label => label?.label || ''}
			links={finalLinks}
			title={t('category.relatedSearchesCity.title', {
				baseCategoryName
			})}
		/>
	);
};
