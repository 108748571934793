import React, { Component, useEffect, useState } from 'react';
import { Localize } from '@planity/components';
import groupBy from 'lodash/groupBy';
import { isNativeApp } from '@planity/webview';
import Business, { schemaType } from './business';
import { NoResults } from './no_results';
import { capitalize, formatPlaceTitle, scrollTo } from '@planity/helpers';
import isEqual from 'lodash/isEqual';
import { useLocalization, useTranslation } from '@planity/localization';
import styles from './results.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import { cloudinaryURL } from '@planity/components/cloudinary_picture';
import credentials from '@planity/credentials';

export function Results({
	businesses,
	availabilities,
	hasMap,
	onBusinessHover,
	isLoading,
	search,
	services,
	hoveredBusinessId,
	pageSearch,
	userHasPickedDate
}) {
	useStyles(styles);
	const { t } = useTranslation();
	const { language, localizedBasePath } = useLocalization();
	const [isCollapsed, setCollapsing] = useState(false);
	const { customers = [], directory = [] } = groupBy(
		businesses || [],
		businessStatus
	);
	const isFirstPage = pageSearch === 1;
	const isDateSearchOverSecondPage = userHasPickedDate && pageSearch > 1;

	const hasDirectoryTitle =
		isFirstPage &&
		directory.length > 0 &&
		(customers.length > 0 ||
			businesses.some(biz => biz.plStatus >= 1 || biz._plStatus === 1));

	useEffect(() => {
		if (!isLoading && !isDateSearchOverSecondPage) {
			scrollTo({ top: 0 });
		}
	}, [isLoading, isDateSearchOverSecondPage]);
	const classes = classNames.bind(styles)({
		results: true,
		pointerEventNone: isCollapsed,
		hasShadow: !customers.length && isFirstPage
	});

	const getCategoryTitle = ({ category, parentCategory }) => {
		if (category) {
			return t('search.directory.otherCategoriesInOtherBusinesses', {
				categoryName: category?.name
			});
		}
		if (parentCategory) {
			return t('search.directory.otherCategories', {
				categoryName: capitalize(
					parentCategory?.name?.toLowerCase(),
					['de'].includes(language)
				)
			});
		}
		return t('search.directory.default');
	};

	const placeTitle = formatPlaceTitle(
		{
			...search,
			searchPlace: search,
			leafPlace: search.place || search.parentPlace
		},
		t
	);

	return (
		<div className={classes}>
			{!customers.length && isFirstPage && (
				<NoResults bookable className={styles.noResults} />
			)}
			<div className={styles.items}>
				{renderBusinesses({
					businesses: customers,
					availabilities,
					services: services,
					hasMap,
					onBusinessHover,
					search: search,
					hoveredBusinessId,
					isCollapsed: isCollapsed,
					isCollapsing: isCollapsing => {
						setCollapsing(isCollapsing);
					},
					withItemList: true,
					localizedBasePath
				})}
			</div>
			{hasDirectoryTitle && (
				<h3 className={styles.directoryTitle}>
					<Localize
						args={{
							categoryTitle: getCategoryTitle(search),
							placeTitle
						}}
						text={'search.directoryTitle'}
					/>
				</h3>
			)}
			<div className={styles.items}>
				{renderBusinesses({
					businesses: directory,
					availabilities,
					services: services,
					hasMap,
					onBusinessHover,
					search: search,
					isCollapsed: isCollapsed,
					isCollapsing: isCollapsing => {
						setCollapsing(isCollapsing);
					}
				})}
			</div>
			<div
				className={classNames.bind(styles)({
					overlay: true,
					loadingOverlay: isLoading
				})}
			/>
		</div>
	);
}

function renderBusinesses({
	businesses,
	availabilities,
	services,
	onBusinessHover,
	search,
	hoveredBusinessId,
	isCollapsed,
	isCollapsing,
	withItemList,
	localizedBasePath,
	...props
}) {
	return (
		<>
			{businesses.map((business, index) => (
				<BusinessContainer
					availabilities={availabilities[business.objectID]}
					business={business}
					hoveredBusinessId={hoveredBusinessId === business.objectID}
					index={index}
					isCollapsed={isCollapsed}
					isCollapsing={isCollapsing}
					key={business.objectID}
					search={search}
					services={services[business.objectID]}
					trackClicks={index < 5}
					withItemList={withItemList}
					onBusinessHover={onBusinessHover}
					{...props}
				/>
			))}
			{!!withItemList && (
				<ItemList
					businesses={businesses}
					localizedBasePath={localizedBasePath}
				/>
			)}
		</>
	);
}

class BusinessContainer extends Component {
	constructor(props) {
		super(props);
		this.state = { isCollapsing: false };
	}

	shouldComponentUpdate(prevProps) {
		return (
			prevProps.hoveredBusinessId !== this.props.hoveredBusinessId ||
			!isEqual(prevProps.services, this.props.services) ||
			!isEqual(prevProps.availabilities, this.props.availabilities)
		);
	}

	render() {
		return (
			<Business
				{...this.props}
				isCollapsed={this.props.isCollapsed}
				isCollapsing={collapse => {
					this.props.isCollapsing(collapse);
				}}
			/>
		);
	}
}

function businessStatus(business) {
	if (business.plStatus >= 3) return 'customers';
	if (isNativeApp) return 'ignored';
	return 'directory';
}

function ItemList({ businesses, localizedBasePath }) {
	const list = (businesses || [])
		.filter(business => !!(business.pictures || []).length)
		.map((business, i) =>
			Object.assign(
				{
					'@type': 'ListItem',
					'position': i + 1,
					'item': {
						'@type': schemaType(business),
						'name': business.name,
						'address': {
							'@type': 'PostalAddress',
							'streetAddress': business.address.street,
							'addressLocality': business.address.locality,
							'postalCode': business.address.postalCode,
							'addressCountry': business.countryCode
						},
						'image': business.pictures.map(picture => {
							const publicId =
								typeof picture === 'string'
									? `${picture.split('/upload/').pop()}`
									: picture?.id;
							return cloudinaryURL(publicId, picture);
						}),
						'aggregateRating': aggregateRating(business),
						'url': `https://www.${credentials.HOST}${localizedBasePath}/${business.slug}`
					}
				},
				business.address
					? {
							address: {
								'@type': 'PostalAddress',
								'addressLocality': business.address.locality || '',
								'postalCode': business.address.postalCode || '',
								'streetAddress': business.address.street || '',
								'addressCountry': business.address.country || 'France'
							}
					  }
					: {}
			)
		);
	return (
		<script
			dangerouslySetInnerHTML={{
				__html: JSON.stringify(
					{
						'@context': 'https://schema.org',
						'@type': 'ItemList',
						'itemListElement': list
					},
					null,
					process.env.NODE_ENV === 'development' ? 2 : 0
				)
			}}
			type='application/ld+json'
		/>
	);
}

function aggregateRating({ globalRating, ratingsCount }) {
	if (!globalRating || !ratingsCount) {
		return null;
	}
	return {
		'@type': 'AggregateRating',
		'ratingValue': +globalRating.toFixed(1),
		'reviewCount': ratingsCount
	};
}
